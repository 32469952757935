<script setup>
 const props = defineProps({
  opened: {
   type: Boolean,
   required: true,
  },
  classes: {
   type: String,
   required: false,
   default: '',
  },
 });

 // Props
 const { opened } = toRefs(props);

 // Emits
 const emit = defineEmits(['close']);

 // Events
 const handleClose = () => {
  emit('close');
 };
</script>

<template>
 <MessModal :opened="opened" @close="handleClose" :classes="classes">
  <template #close>
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M17 3L4 16" stroke="currentColor" />
    <path d="M17 16L4 3" stroke="currentColor" />
   </svg>
  </template>
  <template #content>
   <slot />
  </template>
 </MessModal>
</template>

<style lang="scss">
 .mess-modal {
  background: rgba(255, 255, 255, 0.5);

  .body {
   top: 0;
   left: 0;
   background: white;
   width: min(90%, 1130px);

   .close {
    width: 50px;
    height: 50px;
    transition: 250ms ease-in-out;

    &:hover {
     transform: scale(0.8);
     opacity: 0.8;
    }

    svg {
     @include absolute-center;
     width: 20px;
     height: 20px;
    }
   }
  }

  .full-video-wrapper {
   width: 100%;
   height: 100%;
   aspect-ratio: 1.58;

   iframe {
    width: 100%;
    height: 100%;
   }
  }

  @media (max-width: $tablet) {
   .body {
    // padding: 1.5rem;
    // padding-top: 4rem;
   }
  }
 }
</style>
